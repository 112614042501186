import QRCodeStyling from "qr-code-styling";
import { useEffect, useRef, useState } from "react";
import "./QRGenerator.css";
import PaymentLogo from "./PaymentLogo";

const qrCode = new QRCodeStyling({
  width: 240,
  height: 240,
  type: "svg",
  data: "https://cash.app/$NikkiHartley1?qr=1",
  // image: "https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg",
  dotsOptions: {
    color: "#000000",
    type: "dots",
  },
  cornersSquareOptions: {
    type: "extra-rounded",
  },
  cornersDotOptions: {
    type: "dit",
  },
  backgroundOptions: {
    color: "#ffffff",
  },
  imageOptions: {
    crossOrigin: "anonymous",
    margin: 10,
  },
  qrOptions: {
    errorCorrectionLevel: "L",
  },
});

function QRGenerator({ url = "", onUrlChange }) {
  const ref = useRef(null);

  useEffect(() => {
    qrCode.append(ref.current);
  }, []);

  useEffect(() => {
    qrCode.update({
      data: url,
    });
  }, [url]);

  function handleUrlChange(e) {
    e.preventDefault();
    onUrlChange(e.target.value);
  }

  return (
    <div className="QRGenerator">
      <div className="paymentQR">
        <div ref={ref} className="QRCodeViewer" />
        <PaymentLogo url={url} />
      </div>
      <textarea
        value={url}
        rows={3}
        className="QRContent"
        onChange={handleUrlChange}
      />
    </div>
  );
}

export default QRGenerator;
